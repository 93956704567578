.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-xxkadm[aria-checked='true'],
.css-xxkadm[data-checked] {
  background-color: #41bc3f !important;
  border-color: #41bc3f !important;
}

.css-xxkadm:focus,
.css-xxkadm[data-focus] {
  box-shadow: none !important;
}

@media screen and (max-width: 768px) {
  .react-multi-carousel-list {
    overflow-x: scroll !important;
  }
}
.react-multi-carousel-list::-webkit-scrollbar {
  display: none;
}
.react-multi-carousel-list::-webkit-scrollbar-track {
  display: none;
}
.react-multi-carousel-list::-webkit-scrollbar-thumb {
  display: none;
}
